<template>
    <svg
        id="Outline"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        enable-background="new 0 0 48 48"
        xml:space="preserve"
        viewBox="0 0 48 48"
    >
        <g>
            <path
                d="M42,9.49403c-5.7334,0-11.47461-1.35547-16.60254-3.91992c-0.43964-0.21948-0.95593-0.61029-1.46912-0.56403
                c-0.48419,0.0437-0.9032,0.35297-1.32581,0.56403C17.47461,8.13856,11.73389,9.49403,6,9.49403c-0.55225,0-1,0.44727-1,1v8.44629
                c0,11.38379,7.71338,21.2627,18.75732,24.02344c0.15973,0.03992,0.32568,0.03992,0.48535,0
                C35.28711,40.20301,43,30.3241,43,18.94032v-8.44629C43,9.94129,42.55273,9.49403,42,9.49403z M41,18.94032
                c0,10.38086-6.97754,19.40039-17,22.02148C13.97803,38.34071,7,29.32117,7,18.94032v-7.45898
                c5.70801-0.14844,11.39111-1.56445,16.49658-4.11816L24,7.11219l0.50293,0.25098C29.6084,9.91688,35.29199,11.33289,41,11.48133
                V18.94032z"
                :fill="color"
            />
            <path
                d="M31.64551,34.79774C36.31934,30.84657,39,25.06824,39,18.94422c0-0.55273-0.44727-1-1-1s-1,0.44727-1,1
                c0,5.53418-2.42188,10.75586-6.64551,14.32617c-0.42188,0.35645-0.47461,0.98724-0.11816,1.40912
                C30.58057,35.08704,31.23932,35.14106,31.64551,34.79774z"
                :fill="color"
            />
            <path
                d="M18,18.16102c0,1.93164,0.94482,3.74707,2.5,4.86719v9.29883c0,0.26562,0.10547,0.51953,0.29297,0.70703
                c0,0,2.49915,2.49915,2.5,2.5c0.3739,0.37384,1.03186,0.3822,1.41406,0c0.00183-0.00189,2.5-2.5,2.5-2.5
                c0.1875-0.1875,0.29297-0.44141,0.29297-0.70703v-0.83301c0-0.26562-0.10547-0.51953-0.29297-0.70703l-0.95898-0.95996
                l0.95898-0.95898c0.38531-0.38531,0.38556-1.0285,0-1.41406c-0.00061-0.00061-0.95996-0.95996-0.95996-0.95996l0.95996-0.95996
                c0.1875-0.1875,0.29297-0.44141,0.29297-0.70703v-1.79883c1.55566-1.12012,2.5-2.93555,2.5-4.86719c0-3.30859-2.69141-6-6-6
                S18,14.85242,18,18.16102z M28,18.16102c0,1.41895-0.7666,2.74414-2.00098,3.45996C25.69043,21.79969,25.5,22.12977,25.5,22.48621
                v1.92676l-1.37354,1.37402c-0.39062,0.39062-0.39062,1.02344,0,1.41406l0.95947,0.95996L24.12646,29.12
                c-0.38531,0.38525-0.38544,1.02844,0,1.41406C24.12689,30.53449,25.5,31.90809,25.5,31.90809L24,33.41297l-1.5-1.5v-9.42676
                c0-0.35645-0.18994-0.68652-0.49854-0.86523C20.76709,20.90516,20,19.57996,20,18.16102c0-2.20605,1.79443-4,4-4
                C26.20605,14.16102,28,15.95496,28,18.16102z"
                :fill="color"
            />
            <circle
                cx="24"
                cy="16.99403"
                r="1"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Security',
    props: {
        color: {
            type: String
        }
    }
}
</script>
<style scoped lang='scss'>
svg {
    width: 22px;
    height: 22px;
}
.main-color path {
    stroke: green;
}
</style>
