export const arrayHowYouFound = [
    {
        value: 'friend',
        text: 'Friend'
    },
    {
        value: 'google',
        text: 'Google'
    },
    {
        value: 'bing',
        text: 'Bing'
    },
    {
        value: 'other_search',
        text: 'Other search engine'
    },
    {
        value: 'facebook',
        text: 'Facebook'
    },
    {
        value: 'instagram',
        text: 'Instagram'
    },
    {
        value: 'other_social',
        text: 'Other social media'
    }
]
